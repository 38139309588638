import React, { useEffect, useState } from "react";
import "./hoverPopup.css";

const HoverPopup = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 35000);
  }, [35000]);

  return visible ? (
    <div className="popup">
      <span>Hover Over Bullets Points to See Details!</span>
    </div>
  ) : (
    <div />
  );
};

export default HoverPopup;
