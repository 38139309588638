import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import * as React from "react";
import HoverText from "./Hover";
import HoverPopup from "./hoverPopup";

// import TimelineData from "./timelineData";

const TimelineData = {
  AIMS: [
    {
      Text: "Designed and coded a cloud-native web app.",
      Tip: "Founded, designed, and implemented a data-rich cloud-native web-app to bring AI and automation to investing and trading analysis to all for free using Django, React, and AWS RDS, and deployed to EC2 (Docker and NGINX).",
    },
    {
      Text: "Integrated NLP and sentiment analysis.",
      Tip: "Leveraged natural language processing (Spacy/NLTK) to scrape and extract stock names and mentions and perform sentiment analysis on trading posts from Reddit.",
    },
    {
      Text: "Building an algorithmic trading pipeline!",
      Tip: "Engineering a multi-threaded backend (Go) to enable users to arrange and monitor algorithmic pipelines.",
    },
  ],

  Chevron: [
    {
      Text: "Productionized ML models.",
      Tip: "Realized $5M of value by contributing to architecture, implementing training and inference pipelines, and deploying machine learning models to production on Azure.",
    },
    {
      Text: "Assembled data pipelines.",
      Tip: "Assembled automated data pipelines for extracting, transforming, and loading data (ETL) across applications and Azure Data Lake via Apache Spark (PySpark) and Azure Data Factory.",
    },
    {
      Text: "Collabed with SMEs.",
      Tip: "Increased ML models’ accuracy by up to %45 via collaborating with subject matter experts (SMEs) for exploratory data analysis, experiments, hyper-parameter tuning, feature extraction, and model diagnostics.",
    },
    {
      Text: "Mentored data science students.",
      Tip: "Boosted Chevron’s competency in data science and ML by mentoring nine engineers in a 6-month DS program.",
    },
    {
      Text: "Optimized inference APIs.",
      Tip: "Reduced image classification inference time by 90% via migrating model deployment (TensorFlow) and inference API from Databricks Jobs to serverless Azure Functions.",
    },
  ],
  AMEX: [
    {
      Text: "Developed a Go package.",
      Tip: "Developed a production-deployed Go package for streamlining instrumenting Golang micro-services and exposing performance metrics using the Prometheus framework.",
    },
    {
      Text: "Prototyped an ML solution.",
      Tip: "Prototyped a machine learning solution (Python) for lowering downtime and validated it by building server/client (Go) micro-services (docker-compose/shell for automation).",
    },
  ],
  RA: [
    {
      Text: "Validated predictive ML solutions.",
      Tip: "Validated a predictive Naïve Bayes-based model for vibration root cause analysis in a cross-functional team.",
    },
    {
      Text: "Ran experiments and gathered data.",
      Tip: "Designed and ran experiments, obtained data, and liaised with embedded IoT SWE team for edge implementation.",
    },
  ],
  VGAE: [
    {
      Text: "Performed EDA and build a graph with time series data.",
      Tip: "Performed exploratory data analysis and cleaning (Python), investigated data quality issues, and built a graph (NetworkX) with time-series traffic congestion signals defined on its nodes for graph analytics research.",
    },
    {
      Text: "Developed a Variational Graph Auto-encoder model.",
      Tip: "Developed a Variational Graph Auto-encoder (TensorFlow) model for data imputation and benchmarked against Non-negative Matrix Factorization and Singular Value Decomposition.",
    },
  ],
};

const aggie_logo =
  "https://resumearash.blob.core.windows.net/images/tamu_logo.png?sp=r&st=2021-07-19T15:04:03Z&se=2023-01-01T00:04:03Z&spr=https&sv=2020-08-04&sr=b&sig=7qc8skr60TZ15Wzfeqy3ZhvSF%2BVsRP5emfHwRRien%2Fo%3D";

const amex_logo =
  "https://resumearash.blob.core.windows.net/images/amex_logo.png?sp=r&st=2021-07-19T15:04:39Z&se=2023-01-01T00:04:39Z&spr=https&sv=2020-08-04&sr=b&sig=3JhgJpigt9xDbGyKcd%2Fh6zhA7o%2BmHnSj1lQXvRSfJIA%3D";
const ra_logo =
  "https://resumearash.blob.core.windows.net/images/ra_logo.png?sp=r&st=2021-07-19T15:05:14Z&se=2023-01-01T00:05:14Z&spr=https&sv=2020-08-04&sr=b&sig=bdaQBscHg059I4x83g57w3VPuNRrUITdH29Szdo9xC8%3D";
const chevron_logo =
  "https://resumearash.blob.core.windows.net/images/chevron_logo.png?sp=r&st=2021-07-19T15:05:40Z&se=2023-01-01T00:05:40Z&spr=https&sv=2020-08-04&sr=b&sig=KrWLi%2BmBfswAi%2ByqbQ0yJE3NAWz6bduABh9o%2F4wDOek%3D";
const ai_logo =
  "https://resumearash.blob.core.windows.net/images/ai_logo.PNG?sp=r&st=2021-07-02T03:29:46Z&se=2022-07-07T11:29:46Z&spr=https&sv=2020-02-10&sr=b&sig=dc8dyb6q32ducbXRcuR75ws9Oaaq9Zxml5h9FrD%2BY54%3D";

const stonks =
  "https://resumearash.blob.core.windows.net/images/stonks.PNG?sp=r&st=2021-07-19T15:06:19Z&se=2022-12-01T00:06:19Z&spr=https&sv=2020-08-04&sr=b&sig=357JCS4l53O7ATCB4wyHt26ZBxeKDcK3WH%2BW4u%2FWkPQ%3D";
// console.log(process.env);

const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

function Timeline() {
  return (
    <div style={{ minWidth: "350px" }}>
      <HoverPopup></HoverPopup>

      <VerticalTimeline layout="1-column-left">
        {/* ################################################# aims ################################################### */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2020 - Now"
          iconStyle={{
            background: "rgb(33, 150, 243)",
            color: "#fff",
          }}
          icon={
            <img
              src={stonks}
              alt="React Logo"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "50%",
              }}
            ></img>
          }
          contentStyle={{ background: colors.platinum, color: "#0" }}
        >
          <h3 className="vertical-timeline-element-title">
            {/* <a href="https://aimeetstocks.com" target="_blank"> */}
            aimeetstocks[dot]com
          </h3>
          <p>
            <ul>
              <li key="id1">
                <HoverText
                  toText={TimelineData.AIMS[0].Text}
                  toTip={TimelineData.AIMS[0].Tip}
                ></HoverText>
              </li>
              <li key="id2">
                <HoverText
                  toText={TimelineData.AIMS[1].Text}
                  toTip={TimelineData.AIMS[1].Tip}
                ></HoverText>
              </li>
              <li key="id3">
                <HoverText
                  toText={TimelineData.AIMS[2].Text}
                  toTip={TimelineData.AIMS[2].Tip}
                ></HoverText>
              </li>
            </ul>
            <b>
              Python | JavaScript | Go | Bash | Docker | Django | Django REST
              framework | React | HTML/CSS | AWS | SQL | NGINX | NLP
            </b>
          </p>
        </VerticalTimelineElement>
        {/* ################################################# Chevron ################################################### */}
        <VerticalTimelineElement
          contentStyle={{ background: colors.platinum, color: "#0" }}
          className="vertical-timeline-element--work"
          date="2020 - Now"
          iconStyle={{ borderRadius: "10%" }}
          icon={
            <img
              src={chevron_logo}
              alt="React Logo"
              style={{
                height: "100%",
                width: "100%",
              }}
            ></img>
          }
        >
          <h3 className="vertical-timeline-element-title">
            <a>Chevron</a>
          </h3>
          <h5 className="vertical-timeline-element-subtitle">Houston, TX</h5>
          <p>Machine Learning Engineer</p>
          <p>
            <ul>
              <li key="id4">
                <HoverText
                  toText={TimelineData.Chevron[0].Text}
                  toTip={TimelineData.Chevron[0].Tip}
                ></HoverText>
              </li>
              <li key="id5">
                <HoverText
                  toText={TimelineData.Chevron[1].Text}
                  toTip={TimelineData.Chevron[1].Tip}
                ></HoverText>
              </li>
              <li key="id6">
                <HoverText
                  toText={TimelineData.Chevron[2].Text}
                  toTip={TimelineData.Chevron[2].Tip}
                ></HoverText>
              </li>
              <li key="id7">
                <HoverText
                  toText={TimelineData.Chevron[3].Text}
                  toTip={TimelineData.Chevron[3].Tip}
                ></HoverText>
              </li>
              <li key="id8">
                <HoverText
                  toText={TimelineData.Chevron[4].Text}
                  toTip={TimelineData.Chevron[4].Tip}
                ></HoverText>
              </li>
            </ul>
            <b>
              PyTorch | TensorFlow | XGBoost | Keras | Postgres | scikit-learn |
              Transfer Learning | Pandas | Numpy | OpenCV | NLTK | Ensemble
              Methods | Dash | Spark | ETL
            </b>
          </p>
        </VerticalTimelineElement>
        {/* ################################################# reserach ################################################### */}
        <VerticalTimelineElement
          contentStyle={{ background: colors.platinum, color: "#0" }}
          className="vertical-timeline-element--work"
          date="Jan - Dec 2019"
          iconStyle={{ color: "#fff", borderRadius: "50%" }}
          icon={
            <img
              src={aggie_logo}
              alt="React Logo"
              style={{
                borderRadius: "50%",
                height: "100%",
                width: "100%",
              }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            <a>Research</a>
          </h3>
          <h5 className="vertical-timeline-element-subtitle">
            College Station, TX
          </h5>
          <p>
            <ul>
              <li key="id9">
                <HoverText
                  toText={TimelineData.VGAE[0].Text}
                  toTip={TimelineData.VGAE[0].Tip}
                ></HoverText>
              </li>
              <li key="id10">
                <HoverText
                  toText={TimelineData.VGAE[1].Text}
                  toTip={TimelineData.VGAE[1].Tip}
                ></HoverText>
              </li>
            </ul>
            <b>
              Python | TensorFlow | Research | Graph | scikit-learn | Pandas |
              Numpy | Exploratory Data Analysis (EDA)
            </b>
          </p>
        </VerticalTimelineElement>
        {/* ################################################# Second internship ################################################### */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: colors.platinum, color: "#0" }}
          date="May - Aug 2019"
          iconStyle={{
            borderRadius: "20%",
          }}
          icon={
            <img
              src={amex_logo}
              alt="React Logo"
              style={{
                borderRadius: "20%",
                height: "100%",
                width: "100%",
              }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            <a>American Express</a>
          </h3>
          <h5 className="vertical-timeline-element-subtitle">Phoenix, AZ</h5>
          <p>Software Engineering Intern</p>
          <p>
            <ul>
              <li key="id11">
                <HoverText
                  toText={TimelineData.AMEX[0].Text}
                  toTip={TimelineData.AMEX[0].Tip}
                ></HoverText>
              </li>
              <li key="id12">
                <HoverText
                  toText={TimelineData.AMEX[1].Text}
                  toTip={TimelineData.AMEX[1].Tip}
                ></HoverText>
              </li>
            </ul>
            <b>
              Go | Python | Docker | NGINX | Grafana | scikit-learn | Pandas |
              Numpy | Prometheus | REST APIs | gRPC | Networking
            </b>
          </p>
        </VerticalTimelineElement>
        {/* ################################################# First internship ################################################### */}
        <VerticalTimelineElement
          contentStyle={{ background: colors.platinum, color: "#0" }}
          className="vertical-timeline-element--work"
          date="May - Aug 2018"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={
            <img
              src={ra_logo}
              alt="React Logo"
              style={{
                borderRadius: "40%",
                height: "100%",
                width: "100%",
              }}
            ></img>
          }
        >
          <h3 className="vertical-timeline-element-title">
            <a>Rockwell Automation </a>
          </h3>
          <h5 className="vertical-timeline-element-subtitle">
            College Station, TX
          </h5>
          <p>
            <ul>
              <li key="id13">
                <HoverText
                  toText={TimelineData.RA[0].Text}
                  toTip={TimelineData.RA[0].Tip}
                ></HoverText>
              </li>
              <li key="id14">
                <HoverText
                  toText={TimelineData.RA[1].Text}
                  toTip={TimelineData.RA[1].Tip}
                ></HoverText>
              </li>
            </ul>
            <b>
              Python | scikit-learn | Pandas | Numpy | Data Acquisition | PLC
              Programming
            </b>
          </p>
        </VerticalTimelineElement>

        {/* ################################################# Became aggie ################################################### */}
        <VerticalTimelineElement
          // style={{ border: "2px groove blue" }}
          className="vertical-timeline-element--work"
          date="2016 - 2019"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={
            <img
              src={aggie_logo}
              alt="React Logo"
              style={{
                borderRadius: "40%",
                height: "100%",
                width: "100%",
              }}
            />
          }
          contentStyle={{ background: colors.platinum, color: "#0" }}
        >
          <h3 className="vertical-timeline-element-title">Became an Aggie!</h3>
          <h5 className="vertical-timeline-element-subtitle">
            College Station, TX
          </h5>
          <p>
            B.S. in Electrical &amp; Computer Engineering (CS Minor) | GPA:
            3.8/4
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Timeline;
